<template>
    <div class="select is-primary">
        <label :for="id">{{label}}</label>
        <select :name="id" :id="id">
            <option v-for="number in numbers" :key="number" :value="number">{{number}}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: ['numbers', 'id', 'label']
}
</script>

<style scoped>
    
</style>