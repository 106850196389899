<template>
    <div>
        <div><h1>Zakázka</h1>
            <!-- <td>velikost disku</td>
            komponenta pro výběr velikosti disku -->
            <number-selector :numbers="numbers" id="wheel-size-selector" label="Velikost kola: "/>
            <!-- komponenta pro výber počtu disku -->
        </div>
    </div>
</template>

<script>
    import NumberSelector from '../components/ui/numberSelector.vue'



    export default {
        name: 'PriceCalculator',
        components: {
                NumberSelector,
        },
        data: () => {
            return {
                numbers: [10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
            }
        }
    }
</script>

<style>
    .select:not(.is-multiple):not(.is-loading)::after {
        right: 2.125em;
    }
    .select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after{
        top: 110%;
    }
</style>